// Here you can add other styles

.invalid-feedback {
  display: unset!important;
}

.with-transition {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.min-w-100 {
  min-width: 100px;
}

.min-w-150 {
  min-width: 150px;
}

.min-w-200 {
  min-width: 200px;
}

.min-w-250 {
  min-width: 250px;
}

.min-w-300 {
  min-width: 300px;
}

html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
  padding-left: 1rem!important;
  white-space: normal!important;
}
